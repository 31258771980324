import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import Search from './components/Search.js';
import Properties from './components/Properties';
import Testimonials from './components/Testimonials';

class Home extends React.Component {

    state = {
        featured: [],
    };

    componentDidMount() {
        axios.get("https://api-v2.plumproperties.im/properties?featured=true")
        .then((res) => {
            this.setState({
                featured: res.data
            });
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Plum Properties Estate Agents - Find your perfect home on the Isle of Man</title>
                    <meta name="description" content="Plum Properties is an online Estate Agent & Property Management company based in the Isle of Man. We take the stress out of property buying, selling & renting." />
                </Helmet>

                <header className="large">
                    <div>
                        <h1>Find your perfect home</h1>
                        <Search />
                        <div>
                            <Link to="/sell-your-property#anchor" className="btn" >Book an appraisal</Link>
                            <NavLink to="/sign-up" className="btn" >Create property alert</NavLink>
                        </div>
                    </div>
                    <img src={require('../images/header.jpg')} alt="Plum Properties Header Image" />
                </header>

                <section>
                    <div className="container">
                        <h2>Featured Properties</h2>
                        <Properties
                            properties={this.state.featured}
                        />
                        <NavLink to="/buy" className="btn center">See All Properties</NavLink>
                    </div>
                </section>

                <div className="split">
                    <section className="grey">
                        <div>
                            <h2>About</h2>
                            <p>We are a fully licensed, online-focused Isle of Man estate agency. Whether marketing and selling your property, helping you find your dream home or letting and managing your property, our focus is on delivering the best service and experience that we can in a cost-effective way, ensuring that you are receiving value for your money.</p>
                            <p>We do this by utilising state-of-the-art marketing techniques including targeted online advertising through multiple mediums and platforms to ensure your property reaches the right audience. Our functionality allows you to book property valuations and property viewings, sign-up for tailored property alerts and track the progress of your property transaction 24 hours a day.</p>
                            <p>Being online focused allows us to pass on cost efficiencies to our customers, meaning we can provide all of the services of a local estate agency in an affordable, flexible and transparent way.</p>
                            <p>In 2024, Plum Properties became part of Partners Real Estate, an international property group headquartered in the Isle of Man. As part of Partners, Plum Properties has access to the resources, network, and expertise to further enhance the service it delivers for its valued clients across the Isle of Man.</p>
                        </div>
                    </section>
                    <img src={require('../images/about.jpg')} alt="Plum Properties" />
                </div>

                <section>
                    <div className="container">
                        <h2 className="left">History of Plum</h2>
                        <p>We started out life as a residential property lettings and management business in 2015 under the name of Total Media Rentals Ltd for a portfolio of private clients. Following successfully obtaining our Isle of Man Estate Agents licence we are trading as Plum Properties and have expanded these services to include selling residential property and representing buyers to help them find their dream Isle of Man home. If you feel you could benefit from our services, please do get in contact.</p>
                    </div>
                </section>

                <Testimonials />
            </>
        );
    }
}

export default Home;
