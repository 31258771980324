import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Home from './Home';
import PropertySearch from './PropertySearch';
import Property from './Property';
import SellYourProperty from './SellYourProperty';
import BuyingServices from './BuyingServices';
import Landlords from './Landlords';
import ManagingAgency from './ManagingAgency';
import Article from './Article';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import SignUp from './SignUp';
import BookAViewing from './BookAViewing';
import MakeAnOffer from './MakeAnOffer';
import ScrollToTop from "./components/ScrollToTop";
import ReactPixel from 'react-facebook-pixel';
import BookAppraisalSuccess from "./BookAppraisalSuccess";
import ReservationAgreementSuccess from "./ReservationAgreementSuccess";

// import '../sass/styles.scss';

class App extends React.Component {

    componentDidMount() {
        ReactPixel.init('2289926061323683');
        ReactPixel.pageView();
    }

    render() {
        return (
        <>
            <Nav />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/buy" component={PropertySearch} />
                <Route path="/let" component={PropertySearch} />
                <Route path="/commercial" component={PropertySearch} />
                <Route exact path="/property/:id" component={Property} />
                <Route exact path="/sell-your-property/thank-you" component={BookAppraisalSuccess} />
                <Route exact path="/sell-your-property" component={SellYourProperty} />
                <Route exact path="/buying-services" component={BuyingServices} />
                <Route exact path="/landlords" component={Landlords} />
                <Route exact path="/managing-agency" component={ManagingAgency} />
                <Route exact path="/news/:id" component={Article} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/book-a-viewing/:property" component={BookAViewing} />
                <Route exact path="/make-an-offer/:property" component={MakeAnOffer} />
                <Route exact path="/reservation-agreement/thank-you" component={ReservationAgreementSuccess} />
            </Switch>
            <Footer />
            <ScrollToTop />
        </>
    )}
}

export default App;
